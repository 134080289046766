@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Space-Mono";
  src: url("../public/fonts/spacemono.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@layer components {
  .navbarIcon {
    @apply text-slate-200 
        text-lg 
        font-medium 
        cursor-pointer;
  }
  .monoSpace {
    font-family: "Space-Mono", monospace;
  }
}

.animate-charcter {
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 6s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

html {
  scroll-behavior: smooth;
}
